import { render, staticRenderFns } from "./CallToActionBlock.vue?vue&type=template&id=30d07e7a"
import script from "./CallToActionBlock.vue?vue&type=script&lang=js"
export * from "./CallToActionBlock.vue?vue&type=script&lang=js"
import style0 from "./CallToActionBlock.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CallToActionButton: require('/vercel/path0/components/CallToActionButton/CallToActionButton.vue').default})
