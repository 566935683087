
export default {
    props: {
        callToAction: {
            type: Object,
            default: null,
            required: true
        }
    },
    computed: {
        ctaAttrs() {
            if (!this.callToAction) {
                return;
            }

            const attrs = {};

            if (this.callToAction.entryLink.length > 0) {
                attrs.element = 'nuxt-link';
                attrs.to = this.callToAction.entryLink[0].uri;
            }

            if (this.callToAction.externalUrl) {
                attrs.element = 'a';
                attrs.to = null;
                attrs.href = this.callToAction.externalUrl;
            }

            if (this.callToAction.assetLink && this.callToAction.assetLink[0]) {
                attrs.element = 'a';
                attrs.to = null;
                attrs.href = this.callToAction.assetLink[0].url;
                attrs.referrerpolicy = 'strict-origin-when-cross-origin';
            }

            if (this.callToAction.openInNewTab) {
                attrs.target = '_blank';
            }

            return attrs;
        },

        defaultLabel() {
            if (!this.callToAction) {
                return;
            }

            if (this.callToAction.label) {
                return this.callToAction.label;
            }

            if (this.callToAction.entryLink &&
                this.callToAction.entryLink.length &&
                this.callToAction.entryLink[0].title
            ) {
                return this.callToAction.entryLink[0].title;
            }

            return '';
        }
    },
};
