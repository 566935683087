
export default {
    props: {
        title: {
            type: String,
            default: null
        },

        callToAction: {
            type: Array,
            default: null
        }
    }
};
